angular.module('fingerink')
    .service('viewSignerIdCardModal', function ($uibModal) {
        this.modalInstance = null;
        this.openModal = function (id) {
            this.modalInstance = $uibModal.open({
                animation: false,
                backdrop: 'static',
                size: 'lg',
                windowClass: 'signaturemodal',
                controller: 'viewSignerIdCardModalCtrl',
                controllerAs: 'controller',
                templateUrl: 'modals/viewSignerIdCardModal.tpl.html',
                resolve: {
                    urls : function(signatureService){
                        return signatureService.getSignerIdCardUrls(id);
                    }
                }
            });

            return this.modalInstance;
        };

    })
    .controller('viewSignerIdCardModalCtrl', function signModalCtrl($rootScope, $scope, $uibModalInstance, urls) {
        var that = this;
        that.urls = urls.data;
        that.close = function(){
            $uibModalInstance.close();
        };
    });
